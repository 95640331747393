/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $DoctorProfessionalRegisterData = {
  properties: {
    crm: {
      type: 'string',
      isRequired: true,
    },
    crmUF: {
      type: 'string',
    },
    specialties: {
      type: 'array',
      contains: {
        type: 'DoctorSpecialty',
      },
      isRequired: true,
    },
    crmRegisterType: {
      type: 'DoctorCRMRegisterType',
      isRequired: true,
    },
  },
} as const;
