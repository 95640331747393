/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetWorkflowStepsRequest } from '../models/GetWorkflowStepsRequest';
import type { GetWorkflowStepsResponse } from '../models/GetWorkflowStepsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class WorkflowService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get all the steps for a given workflow
   * @param requestBody
   * @returns GetWorkflowStepsResponse Returns success/APIError
   * @throws ApiError
   */
  public list(
    requestBody: GetWorkflowStepsRequest,
  ): CancelablePromise<GetWorkflowStepsResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/workflow/list',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
