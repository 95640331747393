/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Admin = {
  properties: {
    id: {
      type: 'string',
      isRequired: true,
    },
    email: {
      type: 'string',
    },
    roles: {
      type: 'array',
      contains: {
        type: 'string',
      },
    },
    permissions: {
      type: 'dictionary',
      contains: {
        type: 'array',
        contains: {
          type: 'string',
        },
      },
    },
    settings: {
      properties: {
        featureFlags: {
          properties: {
          },
        },
      },
    },
  },
} as const;
