/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RegistrationDenyRequest = {
  professionalId: string;
  action: RegistrationDenyRequest.action;
};

export namespace RegistrationDenyRequest {

  export enum action {
    DENY = 'DENY',
  }


}

