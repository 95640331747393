/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $StatusHistory = {
  properties: {
    event_id: {
      type: 'string',
    },
    time: {
      type: 'string',
    },
    email: {
      type: 'string',
    },
    previous_status: {
      type: 'string',
    },
    status: {
      type: 'string',
    },
    status_reason: {
      type: 'string',
    },
    internal_status_reason: {
      type: 'string',
    },
    document: {
      type: 'File',
    },
    compliance_recommendation: {
      type: 'ComplianceRecommendation',
    },
  },
} as const;
