/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SaveProfessionalInfoRequest = {
  properties: {
    cpf: {
      type: 'string',
      isRequired: true,
    },
    name: {
      type: 'string',
      isRequired: true,
    },
    registerNumber: {
      type: 'string',
      isRequired: true,
    },
    registerNumberState: {
      type: 'string',
      isRequired: true,
    },
    birthDate: {
      type: 'number',
      isRequired: true,
      format: 'int64',
    },
    gender: {
      type: 'GenderTS',
      isRequired: true,
    },
    maritalStatus: {
      type: 'MaritalStatusTS',
      isRequired: true,
    },
    rg: {
      type: 'string',
      isRequired: true,
    },
    rgState: {
      type: 'string',
      isRequired: true,
    },
    email: {
      type: 'string',
      isRequired: true,
    },
    phone: {
      type: 'string',
      isRequired: true,
    },
    nationality: {
      type: 'string',
      isRequired: true,
    },
    countryOrigin: {
      type: 'string',
      isRequired: true,
    },
    birthCity: {
      type: 'string',
      isRequired: true,
    },
    address: {
      type: 'AddressInfoTS',
    },
  },
} as const;
