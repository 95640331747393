/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetCompanyMembersResponse } from '../models/GetCompanyMembersResponse';
import type { Response } from '../models/Response';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserActionTokenApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Checks the given user action token
   * @returns Response Returns success true when token is valid
   * @throws ApiError
   */
  public check(): CancelablePromise<Response> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user-action-token/check',
    });
  }

  /**
   * Get members from a company given a action token without needing to sign in
   * @returns GetCompanyMembersResponse Returns members from the company which token's owner belongs
   * @throws ApiError
   */
  public getCompanyMembers(): CancelablePromise<GetCompanyMembersResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user-action-token/get-company-members',
    });
  }

  /**
   * Create new signers based on data informed by contractee
   * @param requestBody
   * @returns Response Return sucess true
   * @throws ApiError
   */
  public createSigners(
    requestBody: {
      signers: Array<{
        cpf: string;
        email: string;
        phone: string;
      }>;
    },
  ): CancelablePromise<Response> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user-action-token/create-signers',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Upload company tax form according to a user id
   * @param requestBody
   * @returns Response Return sucess true
   * @throws ApiError
   */
  public uploadCompanyTaxForm(
    requestBody: {
      formURI: string;
      type: string;
    },
  ): CancelablePromise<Response> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user-action-token/upload-company-tax-form',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
