/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RegistrationApproveRequest = {
  professionalId: string;
  action: RegistrationApproveRequest.action;
};

export namespace RegistrationApproveRequest {

  export enum action {
    APPROVE = 'APPROVE',
  }


}

