/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $UserWorkflowStep = {
  properties: {
    status: {
      type: 'UserWorkflowStepStatus',
    },
    step: {
      type: 'WorkflowStep',
    },
  },
} as const;
