/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ListProfessionSpecialtiesResponseData = {
  properties: {
    professionId: {
      type: 'string',
      isRequired: true,
    },
    specialties: {
      type: 'array',
      contains: {
        properties: {
          id: {
            type: 'string',
            isRequired: true,
          },
          name: {
            type: 'string',
            isRequired: true,
          },
          type: {
            type: 'string',
            isRequired: true,
          },
        },
      },
      isRequired: true,
    },
  },
} as const;
