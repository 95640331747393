/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ProfessionalRegister = {
  properties: {
    status: {
      type: 'string',
    },
    register: {
      type: 'string',
    },
    additionalData: {
      type: 'dictionary',
      contains: {
        properties: {
        },
      },
    },
  },
} as const;
