/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ProfessionalListResponseData = {
  properties: {
    id: {
      type: 'string',
    },
    cpf: {
      type: 'string',
    },
    cnpj: {
      type: 'string',
    },
    status: {
      type: 'string',
    },
    name: {
      type: 'string',
    },
    updated_at: {
      type: 'string',
      format: 'datetime',
    },
    register: {
      type: 'ProfessionalRegister',
    },
    sync_status: {
      type: 'ProfessionalSyncStatus',
    },
    specialties: {
      properties: {
      },
    },
    providerId: {
      type: 'string',
    },
  },
} as const;
