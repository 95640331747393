/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $WorkflowStep = {
  properties: {
    id: {
      type: 'WorkflowStepId',
      isRequired: true,
    },
    workflow: {
      type: 'Workflow',
    },
    group: {
      type: 'string',
      isRequired: true,
    },
    order: {
      type: 'number',
      isRequired: true,
    },
    blockedAfter: {
      type: 'WorkflowStepId',
    },
    config: {
      properties: {
      },
    },
  },
} as const;
