/* eslint-disable-next-line */
export async function retry<T extends (...arg0: unknown[]) => any>(
  fn: T,
  maxTry: number,
  {
    sleepInterval = 0,
    /* eslint-disable-next-line */
    stopPredicate = (error: any) => false as boolean,
    retryCount = 1,
  } = {},
): Promise<Awaited<ReturnType<T>>> {
  try {
    return await fn();
  } catch (e) {
    if (retryCount > maxTry || stopPredicate(e)) {
      throw e;
    }
    if (sleepInterval) {
      await new Promise((r) => setTimeout(r, sleepInterval));
    }
    return retry(fn, maxTry, {
      sleepInterval,
      stopPredicate,
      retryCount: retryCount + 1,
    });
  }
}

export function snakeToCamelCase<T>(obj: T): T {
  if (Array.isArray(obj)) {
    /* eslint-disable-next-line */
    return (obj as any).map(snakeToCamelCase);
  }
  if (obj !== null && typeof obj === 'object') {
    const keys = Object.keys(obj);
    if (keys.length) {
      /* eslint-disable-next-line */
      return keys.reduce((acc: any, key) => {
        acc[key.replace(/_\w/g, (match) => match[1].toUpperCase())] =
          /* eslint-disable-next-line */
          snakeToCamelCase((obj as any)[key]);
        return acc;
      }, {});
    }
  }
  return obj;
}

export function sleep(delay: number) {
  return new Promise((resolve) => setTimeout(resolve, delay));
}
