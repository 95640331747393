/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ProfessionalStatus {
  REGISTERING = 'REGISTERING',
  WAITING_APPROVAL = 'WAITING_APPROVAL',
  WAITING_COMPLIANCE = 'WAITING_COMPLIANCE',
  WAITING_SYNCHRONIZATION = 'WAITING_SYNCHRONIZATION',
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}
