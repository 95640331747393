export function safeJSONStringify(obj: unknown) {
  const cache = new WeakSet();
  return JSON.stringify(obj, (_key, value) =>
    typeof value === 'object' && value !== null
      ? cache.has(value)
        ? undefined
        : cache.add(value) && value
      : value,
  );
}
