/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ProfessionalInfoResponseData = {
  properties: {
    professional: {
      type: 'Professional',
    },
    person: {
      type: 'Person',
    },
    company: {
      type: 'Company',
    },
    professional_documents: {
      type: 'array',
      contains: {
        type: 'ProfessionalInfoDocument',
      },
    },
    company_documents: {
      type: 'array',
      contains: {
        type: 'ProfessionalInfoCompanyDocument',
      },
    },
    backgroundCheck: {
      type: 'GetBackgroundCheckResponse',
    },
    validationResults: {
      type: 'array',
      contains: {
        type: 'AdminValidationResult',
      },
    },
    bussiness_partner: {
      type: 'BussinessPartner',
    },
    provider_id: {
      type: 'string',
    },
  },
} as const;
