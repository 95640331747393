/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CompanyToHireInfo = {
  properties: {
    id: {
      type: 'string',
      isRequired: true,
    },
    cnpj: {
      type: 'string',
      isRequired: true,
    },
    name: {
      type: 'string',
      isRequired: true,
    },
    address: {
      type: 'Address',
    },
    extra_specialties: {
      type: 'array',
      contains: {
        type: 'DoctorExtraSpecialty',
      },
    },
    professionals: {
      type: 'array',
      contains: {
        type: 'DoctorBasicInfoResponseData',
      },
    },
  },
} as const;
