/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum MaritalStatus {
  SINGLE = 1,
  MARRIED = 2,
  SEPARATED = 3,
  DIVROCED = 4,
  WIDOWED = 5,
}
