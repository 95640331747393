/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddProfessionalSpecialtiesRequest } from '../models/AddProfessionalSpecialtiesRequest';
import type { AddProfessionalSpecialtiesResponseData } from '../models/AddProfessionalSpecialtiesResponseData';
import type { SaveProfessionalInfoRequest } from '../models/SaveProfessionalInfoRequest';
import type { SaveProfessionalInfoResponseData } from '../models/SaveProfessionalInfoResponseData';
import type { SearchProfessionalBasicInfoRequest } from '../models/SearchProfessionalBasicInfoRequest';
import type { SearchProfessionalBasicInfoResponse } from '../models/SearchProfessionalBasicInfoResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProfessionalService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Retrive professional, person and user basic info by the given professional key data
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public searchProfessionalBasicInfo(
    requestBody: SearchProfessionalBasicInfoRequest,
  ): CancelablePromise<{
    success: boolean;
    data?: SearchProfessionalBasicInfoResponse;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/search-professional-basic-info',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        500: `Server error`,
      },
    });
  }

  /**
   * Save a new person Entity along with its Professional counterpart
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public saveProfessionalInfo(
    requestBody: SaveProfessionalInfoRequest,
  ): CancelablePromise<{
    success: boolean;
    data?: SaveProfessionalInfoResponseData;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/save-professional-info',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        500: `Server error`,
      },
    });
  }

  /**
   * Add new specialties to a given professional
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public addProfessionalSpecialties(
    requestBody: AddProfessionalSpecialtiesRequest,
  ): CancelablePromise<{
    success: boolean;
    data?: AddProfessionalSpecialtiesResponseData;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/professional/add-specialty',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        500: `Server error`,
      },
    });
  }

}
