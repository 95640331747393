/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetUserWorkflowStepsRequest = {
  properties: {
    workflowId: {
      type: 'Workflow',
    },
  },
} as const;
