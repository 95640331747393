/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptUserTermsRequest } from '../models/AcceptUserTermsRequest';
import type { GetUserTermsResponse } from '../models/GetUserTermsResponse';
import type { Response } from '../models/Response';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserTermService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Return the list of terms for the current user
   * @returns GetUserTermsResponse Returns success or APIError
   * @throws ApiError
   */
  public listUserTerms(): CancelablePromise<GetUserTermsResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/term/list',
    });
  }

  /**
   * Accept the given terms for the current user
   * @param requestBody
   * @returns Response Returns success or APIError
   * @throws ApiError
   */
  public accept(
    requestBody: AcceptUserTermsRequest,
  ): CancelablePromise<Response> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/term/accept',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
