/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Signer = {
  properties: {
    id: {
      type: 'string',
    },
    name: {
      type: 'string',
      isRequired: true,
    },
    cpf: {
      type: 'string',
      isRequired: true,
    },
    birthDate: {
      type: 'string',
      isRequired: true,
      format: 'date',
    },
    email: {
      type: 'string',
      isRequired: true,
    },
    type: {
      type: 'SignerType',
      isRequired: true,
    },
    role: {
      type: 'string',
    },
    hirerId: {
      type: 'string',
    },
    hiredCNPJ: {
      type: 'string',
    },
  },
} as const;
