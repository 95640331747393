/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListProfessionSpecialtiesRequest } from '../models/ListProfessionSpecialtiesRequest';
import type { ListProfessionSpecialtiesResponseData } from '../models/ListProfessionSpecialtiesResponseData';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SpecialtyService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * List all specialties under a given profession
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public listProfessionSpecialties(
    requestBody: ListProfessionSpecialtiesRequest,
  ): CancelablePromise<{
    success: boolean;
    data?: ListProfessionSpecialtiesResponseData;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/specialty/list',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        500: `Server error`,
      },
    });
  }

}
