/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ProfessionalRegisterStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
  BYPASSED = 'BYPASSED',
  VALIDATION_PENDING = 'VALIDATION_PENDING',
}
