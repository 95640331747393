/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ListProfessionalsResponse = {
  type: 'all-of',
  contains: [{
    type: 'ListResponse',
  }, {
    properties: {
      metadata: {
        properties: {
          pagination: {
            type: 'PaginationMetadata',
          },
        },
      },
      data: {
        type: 'array',
        contains: {
          type: 'ProfessionalListResponseData',
        },
        isRequired: true,
      },
    },
  }],
} as const;
